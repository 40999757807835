/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  margin-top: 42px;
  margin-bottom: 34px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -3.03030303%;
  width: 106.06060606%;
}
.sub1 > .item {
  float: left;
  flex-direction: column;
  margin-left: 2.85714286%;
  width: 16.57142857%;
  background: url(/images/sub1-item.png) no-repeat 0 0;
}
.sub1 > .item:hover,
.sub1 > .item:focus,
.sub1 > .item.path {
  background-image: url(/images/sub1-item-hover.png);
}
.cb-layout2 .sub1 > .item {
  background-image: url(/images/sub1-item-l2.png);
}
.cb-layout2 .sub1 > .item:hover,
.cb-layout2 .sub1 > .item:focus,
.cb-layout2 .sub1 > .item.path {
  background-image: url(/images/sub1-item-l2-hover.png);
}
div.sub1 .menu {
  color: #fff;
  padding: 17px 0 14px 30px;
  font-family: 'Montserrat', helvetica, sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-position: 0 1em;
  background-size: 27px auto;
  position: relative;
}
div.sub1 .menu .cb-navigation-subtext {
  font-size: 20px;
  position: absolute !important;
  top: 10px;
  left: 0;
  z-index: 2;
}
.cb-layout2 div.sub1 .menu {
  color: #52492c;
}
div.sub1 .menu .edit {
  background: none !important;
}
div.sub1 .item.c1 .menu {
  padding-left: 0;
}
div.sub1 .item.c1 .menu .cb-navigation-subtext {
  display: none;
}
/* LEVEL 2 */
div.sub2 {
  float: left;
  width: 100%;
}
.sub2 > .item {
  margin-right: 30px;
  margin: 10px 30px 10px 0;
}
.sub2 > .item.exit {
  margin-right: 0;
}
div.sub2 .menu {
  color: #fff;
  font-weight: normal;
  font-family: 'Montserrat', helvetica, sans-serif;
  text-transform: uppercase;
  font-size: 14px;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 94%;
}
@media only screen and (max-width: 1560px) {
  .pic.left,
  .pic.right {
    background-size: 350px auto;
  }
}
@media only screen and (max-width: 1400px) {
  .pic.left,
  .pic.right {
    background-size: 250px auto;
  }
}
@media only screen and (max-width: 1256px) {
  .pic.left,
  .pic.right {
    background-image: none;
  }
}
.cb-layout2 #sponsorswidth {
  margin: 0 0 120px;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 3.30188679%;
  margin-left: 3.30188679%;
}
.area .part,
.area > .grid table {
  margin-right: 3.30188679%;
  margin-left: 3.30188679%;
  width: 93.39622642%;
}
.area .tiny {
  width: 18.39622642%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 6.60377358%;
  margin-left: 6.60377358%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 86.79245283%;
}
.area > .slim .tiny {
  width: 36.79245283%;
}
.south {
  width: 100%;
}
.south > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.south h2,
.south .foot {
  margin-right: 3.30188679%;
  margin-left: 3.30188679%;
}
.south .part,
.south > .grid table {
  margin-right: 3.30188679%;
  margin-left: 3.30188679%;
  width: 93.39622642%;
}
.south .tiny {
  width: 18.39622642%;
}
.south > .slim {
  width: 50%;
}
.south > .slim h2,
.south > .slim .foot,
.south > .slim .part,
.south > .slim.grid table {
  margin-right: 6.60377358%;
  margin-left: 6.60377358%;
}
.south > .slim .part,
.south > .slim.grid table {
  width: 86.79245283%;
}
.south > .slim .tiny {
  width: 36.79245283%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 100%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  width: 107.07070707%;
  margin-left: -3.53535354%;
}
.cb-layout2 .main {
  margin-left: 0;
  width: 100%;
}
.south {
  width: 107.07070707%;
  margin-left: -3.53535354%;
}
div.base div.unit {
  width: 50%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */